import simpleParallax from "simple-parallax-js";
const ambientes = document.querySelectorAll(".ambientes-home__item");
let current = null;
ambientes.forEach((ambiente, i) => {
    if (i === 0) {
        current = ambiente;
    }
    ambiente.addEventListener("mouseover", () => {
        current === null || current === void 0 ? void 0 : current.classList.remove("ambientes-home__item--active");
        ambiente.classList.add("ambientes-home__item--active");
        current = ambiente;
    });
});
const capa = document.querySelector(".ambientes__img");
if (capa)
    new simpleParallax(capa, {
        scale: 1.2
    });
