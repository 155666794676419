"use strict";
const config = {
    rootMargin: '0px 0px 150px 0px',
    threshold: 0
};
const preloadImage = (element) => {
    const source = element.getAttribute('data-src') || '';
    element.src = source;
    element.removeAttribute('data-src');
    element.onload = () => {
        element.classList.add('placeholder__image--show');
    };
};
const observer = new IntersectionObserver((entries, self) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            preloadImage(entry.target);
            self.unobserve(entry.target);
        }
    });
}, config);
const imgs = document.querySelectorAll('[data-src]');
imgs.forEach(img => {
    observer.observe(img);
});
