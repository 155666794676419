import Splide from '@splidejs/splide';
const modalSplide = document.querySelector('.modal__splide');
if (modalSplide) {
    const splide = new Splide(modalSplide, {
        type: 'slide',
        perPage: 1,
        perMove: 1,
        arrows: true,
        pagination: true,
        classes: {
            arrows: 'splide__arrows modal__arrows',
            arrow: 'splide__arrow modal__arrow',
            prev: 'splide__arrow--prev modal__arrow--prev',
            next: 'splide__arrow--next modal__arrow--next',
            pagination: 'splide__pagination modal__pagination',
            page: 'splide__pagination__page modal__page',
        },
    }).mount();
    const modal = document.querySelector('.modal');
    const modalTitle = document.querySelector('.modal__title');
    const modalClose = document.querySelector('.modal__close');
    modalClose === null || modalClose === void 0 ? void 0 : modalClose.addEventListener('click', () => {
        modal === null || modal === void 0 ? void 0 : modal.classList.remove('modal--show');
        document.body.classList.remove('body--clipping');
    });
    document.body.addEventListener('click', (e) => {
        const element = e.target;
        if (!element.getAttribute('data-modal'))
            return;
        e.preventDefault();
        modal === null || modal === void 0 ? void 0 : modal.classList.add('modal--show');
        document.body.classList.add('body--clipping');
        const title = element.getAttribute('data-title') || '';
        if (modalTitle)
            modalTitle.textContent = title;
        const imagesJson = element.getAttribute('data-images') || '[]';
        const images = JSON.parse(imagesJson);
        splide.remove(() => true);
        images.forEach((image) => {
            const wrap = document.createElement('div');
            wrap.classList.add('modal__media');
            const img = document.createElement('img');
            img.classList.add('modal__img');
            img.src = image;
            wrap.appendChild(img);
            splide.add(wrap);
        });
    });
}
