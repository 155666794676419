export const getKeys = (keysToGet, obj) => {
    const selectedObj = {};
    for (const key of keysToGet) {
        if (obj.hasOwnProperty(key)) {
            selectedObj[key] = obj[key];
        }
    }
    return selectedObj;
};
export const scrollToElement = (element) => {
    const padding = 50;
    window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - padding,
        behavior: 'smooth'
    });
};
