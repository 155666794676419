"use strict";
const selects = document.querySelectorAll('.select__field');
selects.forEach(select => {
    const listener = () => {
        if (select.value === '') {
            select.classList.add('select__field--default');
            return;
        }
        select.classList.remove('select__field--default');
    };
    select.addEventListener('change', listener);
    listener();
});
