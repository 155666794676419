"use strict";
const homeCinema = document.querySelector('.home__cinema');
const homeClose = document.querySelector('.home__close');
const videoOpen = document.querySelector('.video__open');
const videoElement = document.querySelector('.video-js');
if (videoElement) {
    const videoJs = videojs(videoElement);
    homeClose === null || homeClose === void 0 ? void 0 : homeClose.addEventListener('click', (e) => {
        e.preventDefault();
        homeCinema === null || homeCinema === void 0 ? void 0 : homeCinema.classList.remove('home__cinema--show');
        videoJs === null || videoJs === void 0 ? void 0 : videoJs.pause();
        document.body.classList.remove('body--clipping');
    });
    videoOpen === null || videoOpen === void 0 ? void 0 : videoOpen.addEventListener('click', (e) => {
        e.preventDefault();
        homeCinema === null || homeCinema === void 0 ? void 0 : homeCinema.classList.add('home__cinema--show');
        videoJs === null || videoJs === void 0 ? void 0 : videoJs.play();
        document.body.classList.add('body--clipping');
    });
}
