import Splide from '@splidejs/splide';
const portfolioSplide = document.querySelector('.splide');
if (portfolioSplide) {
    new Splide(portfolioSplide, {
        type: 'slide',
        perPage: 2,
        breakpoints: {
            1439: {
                perPage: 2,
            },
            1023: {
                perPage: 1,
                padding: { right: 150 }
            },
            767: {
                perPage: 1,
                padding: { left: 40, right: 40 },
            }
        },
        perMove: 1,
        arrows: false,
        pagination: false,
        padding: { left: 110, right: 55 },
        classes: {
            arrows: 'splide__arrows portfolio-home__arrows',
            arrow: 'splide__arrow portfolio-home__arrow',
            prev: 'splide__arrow--prev portfolio-home__arrow--prev',
            next: 'splide__arrow--next portfolio-home__arrow--next',
        },
    }).mount();
}
