"use strict";
const dataTriggers = document.querySelectorAll('[data-trigger]');
const navElement = document.querySelector('.nav');
const changeSection = (target) => {
    if (target === null)
        return;
    const section = document.querySelector(`[data-target="${target}"]`);
    if (section) {
        window.location.hash = `#${target}`;
        window.scrollTo({
            behavior: 'smooth',
            top: section.offsetTop
        });
        navElement === null || navElement === void 0 ? void 0 : navElement.classList.remove('nav--open');
    }
    else {
        console.warn('[WARN] Section not found.');
    }
};
const path = window.location.hash.replace('#', '');
dataTriggers.forEach((dataTrigger) => {
    const trigger = dataTrigger.getAttribute('data-trigger');
    if (trigger === path) {
        changeSection(trigger);
    }
    dataTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        changeSection(trigger);
    });
});
