var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import { object, string, setLocale } from 'yup';
import { getKeys, scrollToElement } from './utils';
import { pt } from 'yup-locale-pt';
import axios from 'axios';
import Swal from 'sweetalert2';
import VueMask from 'v-mask';
Vue.use(VueMask);
const footerForm = document.querySelector('.footer__form');
if (footerForm) {
    setLocale(pt);
    const maranhaoId = 10;
    const saoLuisId = 1349;
    new Vue({
        el: footerForm,
        data: {
            nome: '',
            email: '',
            telefone: '',
            estado_id: maranhaoId,
            cidade_id: saoLuisId,
            "g-recaptcha-response": '',
            assunto_id: '',
            mensagem: '',
            errors: {},
            anyError: false,
            loading: false,
            haveScrolled: false,
            progress: 0,
            arquivos: []
        },
        mounted() {
            window.recaptchaCallback = this.getRecaptcha;
        },
        methods: {
            openFiles() {
                this.$refs.file.click();
            },
            handleFiles(e) {
                if (e.target.files)
                    this.arquivos = [...this.arquivos, ...e.target.files];
            },
            removeFile(index) {
                this.arquivos.splice(index, 1);
            },
            getRecaptcha(token) {
                this['g-recaptcha-response'] = token;
                Vue.nextTick();
                this.isValid();
            },
            getData() {
                return getKeys([
                    'nome',
                    'email',
                    'telefone',
                    'estado_id',
                    'cidade_id',
                    'assunto_id',
                    'mensagem',
                    'arquivos',
                    'g-recaptcha-response'
                ], Object.assign({}, this.$data));
            },
            isValid() {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield object({
                            nome: string().required(),
                            email: string().required().email().label('e-mail'),
                            telefone: string().required(),
                            estado_id: string().required().label('estado'),
                            cidade_id: string().required().label('cidade'),
                            assunto_id: string().required().label('assunto'),
                            mensagem: string().required(),
                            "g-recaptcha-response": string().required().label('recaptcha')
                        }).validate(this.getData(), { abortEarly: false });
                        this.errors = {};
                        this.haveScrolled = false;
                        this.anyError = false;
                        return true;
                    }
                    catch (e) {
                        if (e.inner) {
                            const firstError = Object.values(e.inner)[0];
                            const element = document.querySelector(`[name="${firstError.path}"]`);
                            console.log(`[name="${firstError.path}"]`);
                            if (element && !this.haveScrolled) {
                                scrollToElement(element);
                                this.haveScrolled = true;
                            }
                            this.errors = e.inner.reduce(function (collection, error) {
                                if (error.path)
                                    collection[error.path] = error.message;
                                return collection;
                            }, {});
                            this.anyError = true;
                        }
                        return false;
                    }
                });
            },
            onSubmit() {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!(yield this.isValid()))
                        return;
                    const data = this.getData();
                    this.loading = true;
                    axios.post('/api/contatos', data, {
                        onUploadProgress: progressEvent => {
                            if (!progressEvent.total)
                                return;
                            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            this.progress = percentage;
                        },
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                        .then(() => {
                        this.nome = '';
                        this.email = '';
                        this.telefone = '';
                        this.estado_id = maranhaoId;
                        this.cidade_id = saoLuisId;
                        this["g-recaptcha-response"] = '';
                        this.assunto_id = '';
                        this.mensagem = '';
                        this.arquivos = [];
                        Swal.fire({
                            title: 'Contato enviado com sucesso!',
                            icon: 'success',
                            customClass: {
                                popup: 'form__modal',
                                confirmButton: 'form__modal-button',
                            }
                        });
                    })
                        .catch(error => {
                        console.error('Error:', error);
                        Swal.fire({
                            title: 'Ocorreu um erro no envio!',
                            text: 'Tente novamente mais tarde',
                            icon: 'error',
                            customClass: {
                                popup: 'form__modal',
                                confirmButton: 'form__modal-button',
                            }
                        });
                    })
                        .finally(() => {
                        this.loading = false;
                        grecaptcha.reset();
                        this["g-recaptcha-response"] = '';
                    });
                });
            },
            validateInput() {
                if (this.anyError)
                    this.isValid();
            }
        }
    });
}
